export function upSort (key) {
  return function (a, b) {
    var c = a[key]
    var d = b[key]
    if (c < d) {
      return 1
    } else {
      return -1
    }
  }
}
