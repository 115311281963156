<template>
  <div class="request">
    <!-- 头部导航栏 -->
    <div class="card-header">
      <i class="el-icon-edit"></i>
      <span> Deal Favorites</span>
      <el-button icon="el-icon-s-opportunity"
                 @click="centerDialogVisible = true">Buyer must-read</el-button>
    </div>
    <!-- 底部商品栏 -->
    <div class="requestList">
      <div class="reqText">
        If the seller does not process the request within 7 days, the system
        will automatically cancel the request.
      </div>
      <div class="requestList">
        <!-- 商品栏 -->
        <div class="requestBox"
             v-for="(item, index) in reqList"
             :key='index'>
          <div style="display: flex; justify-content: flex-start; width: 40%">
            <!-- 商品图片 -->
            <div class="reqImg">
              <!-- <img :src="item.product_image_url"
                   alt="" /> -->
                   <el-image
                    style="width: 100px; height: 100px"
                    :src="item.product_image_url" >
                  </el-image>
            </div>
            <!-- 商品名称和时间 -->
            <div class="reqGoods">
              <div class="reqName">
                <span :class="item.site_class"></span>
                <span @click="freeGood(item)">{{item.product_title}}</span>
              </div>
              <div class="reqSell"><b>Seller User</b> : {{item.firstname}}  {{item.lastname}}</div>
              <div class="reqTime">{{item.create_date | formatDate}}</div>
            </div>
          </div>
          <!-- 商品卖方状态等 -->
<!--          <div class="reqCuntent">-->

<!--          </div>-->
          <!-- 删除或购买商品 -->
          <div class="reqBtn">
            <el-button type="primary"
                       @click="chatClick(item, true)"
                       class="round"
                       icon="el-icon-chat-dot-round">
              Chat
              <!-- <span v-if="numberBool=item.numberBool"
                    class="numberChat">{{item.numberId}}</span> -->
                    <span v-if="item.numberId"
                    class="numberChat">{{item.numberId}}</span>
            </el-button>
            <el-button type="primary"
                       @click="errorTips(item)"
                       class="error"
                       icon="el-icon-error">Cancel</el-button>
            <el-link :href="item.amazon_url"
                     class="share"
                     target="_blank"
                     icon="el-icon-share"
                     :underline="false">Amazon URL</el-link>
          </div>
          <!-- 消息提醒 -->
          <!-- <span class="information">1</span> -->
        </div>
        <!-- 分页 -->
        <div class="block"
             v-if="reqListBool">
          <div class="blockDiv">
            <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="currentSize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
          </div>
        </div>
        <!-- 聊天室弹出层 -->
          <el-dialog title="chat"
                     :modal-append-to-body="false"
                     :visible.sync="chatTableVisible"
                     width="600px"
                     :before-close="closeChat">
            <!-- 聊天室 -->
            <div class="chatBox">
              <!-- 聊天内容 -->
              <div class="textBox"
                   ref="textBox">
                <!-- 卖方 -->
                <div :class="chatItem.role === 'RULE_SELLER' ? 'chatLeft' : 'chatRight'"
                     v-for="(chatItem, index) in chatList"
                     :key="index">
                  <div :class="chatItem.role === 'RULE_SELLER' ? 'seller' : 'buyer'">{{chatItem.firstname}} {{chatItem.lastname}}</div>
                  <div :class="chatItem.role === 'RULE_SELLER' ? 'sellerText' : 'buyerText'">
                    <p v-if="chatItem.chatBool === true">{{chatItem.content}}</p>
                    <img v-else
                       :src="chatItem.content"
                       alt=""
                       style="max-height: 400px; max-width: 400px">
                    <p class="time" style="color: white">{{chatItem.create_date | formatDate}}</p>
                  </div>
                </div>
              </div>
              <div class="sendText">
                <textarea name=""
                          id=""
                          cols="60"
                          rows="8"
                          v-model="chatTest.chatInpout"></textarea>
                <!-- <i class="el-icon-picture-outline"></i> -->
                <el-upload class="upload-demo"
                           action="https://crediscounts.com/photograph/picture"
                           :headers="chatToken"
                           :on-success="handlePreview"
                           :on-remove="handleRemove"
                           :before-upload="beforeAvatarUpload"
                           list-type="picture"
                           ref='upload'>
                  <i class="el-icon-picture-outline"></i>
                  <div slot="tip"
                       class="el-upload__tip">Can only upload pictures, and no more than 3MB</div>
                </el-upload>
              </div>
              <div class="send">
                <el-button @click="closeChat" type="info">Close</el-button>
                <el-button type="primary"
                           @click="sendChat()" :loading="$store.state.loadingBool">Send</el-button>
              </div>
            </div>
          </el-dialog>
      </div>
    </div>
    <!-- 弹出层 -->
    <el-dialog title="Buyer must-read"
               :modal-append-to-body="false"
               :visible.sync="centerDialogVisible"
               width="500px"
               center>
      <div class="pText">
        <p>
          1. Make purchases on Amazon without the seller's consent. This way you will not get any refund.
        </p>
        <p>
          2. If the seller does not process the request within 3 days, the system will automatically cancel the request. After obtaining the seller's consent, please place an order within 7 days. After 7 days, the system will automatically cancel your request.
        </p>
        <p>
          3. After purchasing on Amazon, please return to the Crediscounts  platform and fill in the order number. After writing a review on Amazon, fill in your Amazon review link at "validate".
        </p>
        <p>
          4. You can get the refund within 7 days after the platform submits the comment link for the corresponding order. If you do not get the refund, please contact the platform email buyer: service@crediscounts.com.
        </p>
        <p>
          5. For any order that does not go through the platform steps and the user completes the transaction through a third party, the platform is not responsible for any problems.
        </p>
        <p>
          6. The buyer should upload the corresponding order number and review link according to the steps. Only when the order reaches “to conclude” status, can the buyer meet the conditions required for the rebate. Otherwise, the buyer can’t get the refund. When the order reaches the “finished” status, the transaction for that order has been completed.
        </p>
        <p>
          7. The buyer can’t delete or change the review after uploading the review link, and can’t return the product without any reason. Otherwise, it will be deemed as a violation and will not be able to get refund.
        </p>
      </div>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="centerDialogVisible = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入接口
import {
  freeGood, // 获取赠品申请请求
  cancelledOrder, // cancle赠品申请
  // allChat, // 所有未读信息
  count, // 用户未读消息
  chat, // 用户发送聊天消息
  chatGet // 用户获取聊天信息
} from '../../api/buyerAfter'
import {
  getToken
} from '../../utils/token'
// 格式化时间
import { formatDate } from '../../utils/data.js'
// 排序
import { upSort } from '../../utils/jsonSort'
export default {
  data () {
    return {
      // 弹出层
      centerDialogVisible: false,
      chatTableVisible: false,
      // 分页
      currentPage: 1,
      currentSize: 10,
      currentTotal: 0,
      // 商品栏
      reqList: [],
      reqListBool: false,
      // 聊天室上传图片列表
      chatToken: '',
      // 消息列表
      chatList: [],
      // 消息内容
      // chatInpout: '',
      numberBool: false,
      timer: '',
      chatTest: {
        fileList: [],
        chatInpout: ''
      }
    }
  },
  filters: {
    // 时间格式化
    formatDate (time) {
      const date = new Date(time)
      // // (new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    }
  },
  created () {
    this.chatToken = { Authorization: getToken() }
    this.$store.commit('getFreestatus', 1)
    this.$store.commit('getCount')
    // 获取赠品请求
    this.getfreeGood(this.currentPage, this.currentSize)
    this.$store.commit('getLoading', false)
  },
  methods: {
    // 获取全部
    getfreeGood (page, size) {
      this.currentSize = size
      // 获取赠品申请请求
      freeGood({
        page: page,
        size: size,
        status: this.$store.state.freeStatus
      }).then(result => {
        if (result.data.code === 200) {
          // console.log(result)
          const testList = []
          this.currentTotal = result.data.data.total
          result.data.data.rows.map(v => {
            // 用户未读消息
            count({
              freeRequestId: v.id
            }).then(result => {
              if (result.data.code === 200) {
                for (const key in v) {
                  if (result.data.data !== 0) {
                    v.numberId = result.data.data
                    v.numberBool = true
                  } else {
                    v.numberBool = false
                  }
                }
                v.img = []
                v.img.push(v.product_image_url)
                testList.push(v)
              }
              // this.reqList = testList
              this.reqList = testList.sort(upSort('id'))
              // 是否显示分页
              if (this.reqList.length > 0) {
                this.reqListBool = true
              } else {
                this.reqListBool = false
              }
            }).catch(err => {
              return err
            })
          })
        }
      }).catch(err => {
        return err
      })
    },
    // 关闭聊天室
    closeChat () {
      this.chatTableVisible = false
      // this.getfreeGood(this.currentPage, this.currentSize)
    },
    // 聊天室弹出层控制
    chatClick (item, boolean) {
      if (boolean) {
        this.reqList.map(v => {
          if (item.id === v.id) {
            delete v.numberId
          }
        })
        this.chatTableVisible = true
      }
      localStorage.setItem('buyer-item', JSON.stringify(item))
      let chatNum = 1
      if (chatNum === 0) {
        clearInterval(this.timer)
      } else {
        // ('chatnum ==>', chatNum)
        chatNum--
        // 用户获取聊天信息
        chatGet({
          freeRequestId: item.id
        }).then(result => {
          if (result.data.code === 200) {
            result.data.data.rows.map(v => {
              if (v.content.indexOf('photograph') < 0) {
                v.chatBool = true
              } else {
                v.chatBool = false
              }
            })
            this.chatList = result.data.data.rows
            this.$nextTick(() => {
              const div = document.getElementsByClassName('textBox')[0]
              div.scrollTop = div.scrollHeight
            })
          }
          // ('resultChat =>', result)
        }).catch(err => {
          return err
        })
      }
    },
    textScroll (item) {
      // this.$nextTick(() => {
      //   const div = document.getElementById('textBox')
      //   if (div.scrollTop < localStorage.getItem('scrollTop')) {
      //     clearInterval(this.timer)
      //     this.timer = setInterval(() => {
      //       chatGet({
      //         freeRequestId: item.id
      //       }).then(result => {
      //         if (result.data.code === 200) {
      //           this.chatList = result.data.data.rows
      //         }
      //         // ('result =>', result)
      //       }).catch(err => {
      //         return err
      //       })
      //     }, 1000)
      //   }
      // })
    },
    // 图片删除
    handleRemove (file, fileList) {
      this.chatTest.fileList = []
      fileList.map(v => {
        this.chatTest.fileList.push(`https://crediscounts.com/photograph/images/${v.response.message}`)
      })
    },
    // 图片上传成功
    handlePreview (file, fileList) {
      this.chatTest.fileList.push(`https://crediscounts.com/photograph/images/${fileList.response.message}`)
    },
    // 图片上传之前
    beforeAvatarUpload (file) {
      // (this.chatTest.fileList)
      const srcSize = file.size < (3072 * 3072)
      const srcReg = /image/g
      const srcBool = srcReg.test(file.type)
      if (!srcBool) {
        this.$message.error('Please upload the address')
        return false
      } else {
        if (!srcSize) {
          this.$message.error('The image size is less than 3MB')
          return false
        } else if (this.chatTest.fileList.length > 2) {
          this.$message.error('Send up to three pictures at a time')
          return false
        }
      }
    },
    // 删除提示
    errorTips (item) {
      // (item)
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        message: h('p', null, [
          h('span', null, 'Are you sure to cancel this request? ')
          // h('i', { style: 'color: teal' }, 'VNode'),
        ]),
        showClose: false,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancle',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done()
            instance.confirmButtonLoading = false
          } else {
            done()
          }
        }
      })
        .then((result) => {
          // ('result ==>', result)
          this.$msgbox({
            title: '消息',
            message: h('p', null, [h('span', null, 'Cancle success ')]),
            showClose: false,
            showCancelButton: false,
            confirmButtonText: 'confirm',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                done()
                cancelledOrder({
                  id: item.id
                }).then(result => {
                  if (result.data.code === 200) {
                    // this.getfreeGood(this.currentPage, this.currentSize)
                    // this.$store.commit('getCount')
                    setTimeout(() => {
                      this.$router.go(0)
                    }, 500)
                  }
                  // ('result ==>', result)
                }).catch(err => {
                  return err
                })
                // ('aaa')
              } else {
                done()
              }
            }
          })
        })
        .catch((err) => {
          return err
        })
    },
    // 分页
    handleSizeChange (val) {
      // (`每页 ${val} 条`)
      this.getfreeGood(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.getfreeGood(val, this.currentSize)
    },
    // 进入赠品页面
    freeGood (item) {
      this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    },
    // 发送消息
    sendChat () {
      const item = JSON.parse(localStorage.getItem('buyer-item'))
      for (const key in this.chatTest) {
        if (key === 'chatInpout') {
          if (this.chatTest.chatInpout === '' && this.chatTest.fileList.length === 0) {
            this.$message.error('Cannot send empty message')
          } else {
            this.$store.commit('getLoading', true)
            this.sendChattest(item, this.chatTest.chatInpout)
          }
        } else if (key === 'fileList') {
          this.$store.commit('getLoading', true)
          this.chatTest.fileList.map(v => {
            this.sendChattest(item, v)
          })
        }
      }
    },
    // 发送消息
    sendChattest (item, v) {
      // console.log(item.id)
      // 用户发送消息
      chat({
        addVO: {
          content: `${v}`,
          free_request_id: item.id
        }
      }).then(result => {
        // ('result ==>', result)
        if (result.data.code === 200) {
          this.$store.commit('getLoading', false)
          localStorage.removeItem('buyer-item')
          this.chatTest.chatInpout = ''
          this.chatClick(item, false)
          this.chatTest.fileList = []
          this.$refs.upload.clearFiles()
        }
      }).catch(err => {
        return err
      })
    }
  }
}
</script>

<style lang='less' scoped>
.request {
  // 头部导航栏
  text-align: left;
  .card-header {
    padding: 0 20px;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
    span {
      margin-left: 10px;
      line-height: 45px;
    }
    /deep/ .el-button {
      margin-left: 10px;
      border-radius: 0;
      padding: 6px 12px;
      background: transparent;
      border: 0;
      font-size: 14px;
      line-height: 20px;
      color: #f86c6b;
      span {
        margin: 0;
      }
      &:hover {
        background-color: #f86c6b;
        color: white;
      }
    }
  }
  // 底部商品栏
  .requestList {
    background-color: white;
    padding: 20px;
    min-height: 150px;
    .reqText {
      padding: 12px 20px;
      width: 45%;
      font-weight: 700;
      color: #333;
      background-color: #fff3cd;
      border: 1px solid #fff3cd;
      border-radius: 5px;
      line-height: 25px;
    }
    //底部商品栏
    .requestList {
      background-color: white;
      padding: 20px;
      // 商品栏
      .requestBox {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        // 商品图片
        .reqImg {
          img {
            max-height: 100px;
            max-width: 100px;
          }
        }
        // 商品名称和时间
        .reqGoods {
          width: calc(100% - 130px);
          margin-left: 30px;
          // margin-right: 250px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100px;
          font-size: 14px;
          .reqName {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
              line-height: 2;
              &:nth-child(2) {
                width: calc(100% - 24px);
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 10px;
                cursor: pointer;
                color: #20a8d8;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .reqTime {
            line-height: 2;
            text-align: left;
          }
          .reqSell {
            width: 95%;
            line-height: 2;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
        // 商品卖方状态等
        .reqCuntent {
          display: flex;
          width: 30%;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-end;
          min-height: 100px;
          font-size: 14px;
          padding-bottom: 15px;
          margin-right: 200px;
          .succ {
            width: 50%;
          }
          .reqSell {
            width: 100%;
            line-height: 2;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
          div {
            line-height: 2;
          }
        }
        // 删除或购买商品
        .reqBtn {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          /deep/ .el-button {
            padding: 5px 10px;
            /deep/ i {
              font-size: 16px;
            }
          }
          /deep/ .el-button--primary.is-plain {
            color: white;
          }
          .success {
            background-color: #4dbd74;
            border-color: #4dbd74;
          }
          .error {
            background-color: #f86c6b;
            border-color: #f86c6b;
          }
          .round {
            background-color: #20a8d8;
            border-color: #20a8d8;
            position: relative;
            .numberChat {
              position: absolute;
              right: -10px;
              top: -10px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background: #f86c6b;
              color: white;
              text-align: center;
              line-height: 20px;
            }
          }
          .share {
            display: block;
            margin-left: 10px;
            font-size: 14px;
            padding: 7px 10px;
            border-radius: 5px;
            background-color: #2f353a;
            border-color: #2f353a;
            color: white;
          }
        }
        // 消息提醒
        .information {
          position: absolute;
          right: -10px;
          top: -10px;
          // display: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          color: white;
          background-color: tomato;
          text-align: center;
          line-height: 20px;
          font-weight: bolder;
        }
      }
    }
  }
  // 弹出层
  /deep/ .el-dialog__header {
    border-bottom: 1px solid #c8ced3;
    padding: 20px 0;
  }
  /deep/.el-dialog__title {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 25px;
    font-weight: bolder;
    padding-left: 20px;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .pText {
    height: 500px;
    width: 500px;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    border-bottom: 1px solid #c8ced3;
    p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.5;
      color: #23282c;
    }
  }
}
</style>
<style scoped>
.reqImg >>> .el-image-viewer__img{
  max-width: 50%;
  max-height: 50%;
}
</style>
